import React from "react"
import { withTranslation } from "react-i18next"
import IconEfficient from "../../../../assets/img/media/solution/erp/Time Management.svg"
import IconOrganization from "../../../../assets/img/media/solution/erp/organization.svg"
import IconTimer from "../../../../assets/img/media/solution/erp/time-left.svg"
import IconHandshake from "../../../../assets/img/media/solution/erp/handshake.svg"
import "./index.scss"

const Resource = props => {
  const { t } = props
  return (
    <div className="resource-main">
      <div className="coverResource">
        <div className="mainBigBG">
          <div className="coverBG">
            <div className="container">
              <span>{t("src.pages.erpPage.resource.title")}</span>
              <div className="coverP">
                <p>{t("src.pages.erpPage.resource.descriptions")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="list-resource container">
          <div className="item">
            <div className="icon">
              <img src={IconEfficient} alt="Efficient management" />
            </div>
            <div className="content">
              <div className="name">
                <span> {t("src.pages.erpPage.resource.efficientTitle")}</span>
              </div>
              <p>{t("src.pages.erpPage.resource.efficientContent")}</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={IconOrganization} alt="Comprehensive Organization" />
            </div>
            <div className="content">
              <div className="name">
                <span>
                  {t("src.pages.erpPage.resource.comprehensiveTitle")}
                </span>
              </div>
              <p>{t("src.pages.erpPage.resource.comprehensiveContent")}</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={IconTimer} alt="Real-time Update" />
            </div>

            <div className="content">
              <div className="name">
                <span>{t("src.pages.erpPage.resource.realTimeTitle")}</span>
              </div>
              <p>{t("src.pages.erpPage.resource.realTimeContent")}</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={IconHandshake} alt="Smooth Collaboration" />
            </div>

            <div className="content">
              <div className="name">
                <span>{t("src.pages.erpPage.resource.smoothTitle")}</span>
              </div>
              <p>{t("src.pages.erpPage.resource.smoothContent")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Resource)
