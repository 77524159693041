import React from "react"
import { withTranslation } from "react-i18next"
import IconGrowth from "../../../../assets/img/media/solution/erp/growth.svg"
import IconSearchData from "../../../../assets/img/media/solution/erp/datalink.svg"
import IconTouch from "../../../../assets/img/media/solution/erp/touch.svg"
import IconCosting from "../../../../assets/img/media/solution/erp/calculator.svg"
import "./index.scss"

const Planning = props => {
  const { t } = props
  return (
    <div className="planning-main">
      <div className="coverPlanning">
        <div className="mainBigBG">
          <div className="coverBG">
            <div className="container">
              <span> {t("src.pages.erpPage.planning.title")}</span>
              <div className="coverP">
                <p>{t("src.pages.erpPage.planning.descriptions")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="list-planning">
          <div className="item">
            <div className="icon">
              <img src={IconGrowth} alt="Productivity" />
            </div>

            <div className="content">
              <div className="name">
                <span>{t("src.pages.erpPage.planning.productivityTitle")}</span>
              </div>
              <p>{t("src.pages.erpPage.planning.productivityContent")}</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={IconSearchData} alt="Data Consistency" />
            </div>
            <div className="content">
              <div className="name">
                <span> {t("src.pages.erpPage.planning.consistencyTitle")}</span>
              </div>
              <p>{t("src.pages.erpPage.planning.consistencyContent")}</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={IconTouch} alt="Accessibility" />
            </div>

            <div className="content">
              <div className="name">
                <span>
                  {" "}
                  {t("src.pages.erpPage.planning.accessibilityTitle")}
                </span>
              </div>
              <p>{t("src.pages.erpPage.planning.accessibilityContent")}</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={IconCosting} alt="Forecasting" />
            </div>

            <div className="content">
              <div className="name">
                <span> {t("src.pages.erpPage.planning.forecastingTitle")}</span>
              </div>
              <p>{t("src.pages.erpPage.planning.forecastingContent")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Planning)
