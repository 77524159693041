import React from "react"
import ERPPage from "../components/solution/erp/index"

const ERP = () => {
  return (
    <>
      <ERPPage />
    </>
  )
}

export default ERP
