import React, { Component } from "react"
import Layout from "../../layout/layout.higherComponent"
import Head from "../../head"
import Introduce from "./introduce"
import Benefit from "./benefit"
import Resource from "./resource"
import Planning from "./planning"
import "./index.scss"

class ERPPage extends Component {
  render() {
    return (
      <>
        <Layout>
          <Head title="Enterprise Resource Planning" />
          <Introduce />
          <Benefit />
          <Resource />
          <Planning />
        </Layout>
      </>
    )
  }
}

export default ERPPage
