import React from "react"
import { withTranslation } from "react-i18next"
import "./index.scss"

const Introduce = props => {
  const { t } = props
  return (
    <div className="coverIntroERP">
      <div className="erpBigBG">
        <div className="coverBg1 ">
          <div className="container">
            <div className="coverContent">
              <div className="content">
                <span>{t("src.pages.erpPage.introduceTitle")}</span>
              </div>
              <div className="coverP">
                <p>{t("src.pages.erpPage.introduceDescriptions")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Introduce)
