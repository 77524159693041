import React from "react"
import { withTranslation } from "react-i18next"
import "./index.scss"

const Benefit = props => {
  const { t } = props
  return (
    <div className="benefit-main">
      <div className="title">
        <span>{t("src.pages.erpPage.benefitTitle")}</span>
      </div>
      <div className="content">
        <p>{t("src.pages.erpPage.benefitContent")}</p>
      </div>
    </div>
  )
}

export default withTranslation()(Benefit)
